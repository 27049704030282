import axios from "axios";
import { Content, Computation, GlobalComputation, TrackProgress } from "../types";
import { getCommonHeaders, processHeaders } from "./service.utils";
import { xmlError } from "./errors.service";

export async function uploadXml(file: File, token: string | null): Promise<Content> {
  try {
    const API_PATH = window._env_.REACT_APP_API_PATH;
    const url = `${API_PATH}/computation/import`;
    const formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        ...getCommonHeaders(token).headers,
        "Content-Disposition": `attachment; filename="${file.name}"`,
      },
    };
    if (formData) {
      const response = await axios.post(url, file, config);
      processHeaders(response.headers);
      return response.data;
    } else {
      console.log("throw xmlError");

      throw xmlError;
    }
  } catch (ex) {
    throw ex;
    // console.log("ex", ex.response);
    // throw new Error(ex);
  }
}

export async function calculateComputation(computationId: number, token: string | null): Promise<string> {
  const API_PATH = window._env_.REACT_APP_API_PATH;
  const url = `${API_PATH}/computation/${computationId}`;
  const config = getCommonHeaders(token);
  const response = await axios.post(url, null, config);
  processHeaders(response.headers);
  return response.data;
}

export async function trackProgress(computationId: number, token: string | null): Promise<TrackProgress> {
  const API_PATH = window._env_.REACT_APP_API_PATH;
  const url = `${API_PATH}/computation/${computationId}/progress`;
  const config = getCommonHeaders(token);
  const response = await axios.get(url, config);
  processHeaders(response.headers);
  return response.data;
}

export function getDownloadPDF(computationId: number | null, token: string | null): Promise<any> {
  const API_PATH = window._env_.REACT_APP_API_PATH;
  const url = `${API_PATH}/computation/${computationId}/PDF`;
  const headers = getCommonHeaders(token);
  const responseType: any = { responseType: "blob" }; // Force to receive data in a Blob Format
  const config = { ...headers, ...responseType };

  const response = axios
    .get(url, config)
    .then((response) => {
      // Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: "application/pdf" });
      // Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      // Open the URL on new Window
      window.open(fileURL);
    })
    .catch((error) => {
      console.log(error);
    });
  return response;
}

export async function getEndResult(
  computationId: number | null,
  mediumId: string | null,
  parameterId: string | null,
  token: string | null
): Promise<Computation> {
  // Computation
  const API_PATH = window._env_.REACT_APP_API_PATH;
  const url = `${API_PATH}/computation/${computationId}`;
  const params = { params: { mediumId, parameterId } };
  const headers = getCommonHeaders(token);
  const config = { ...params, ...headers };

  const response = await axios.get(url, config);
  processHeaders(response.headers);
  return response.data;
}

export async function getAllEndResults(computationId: number | null, token: string | null): Promise<GlobalComputation> {
  const API_PATH = window._env_.REACT_APP_API_PATH;
  const url = `${API_PATH}/computation/${computationId}/results`;
  const config = getCommonHeaders(token);
  const response = await axios.get(url, config);
  processHeaders(response.headers);
  return response.data;
}
