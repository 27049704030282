export const SET_MAXIMIZE_PREFERENCE = "SET_MAXIMIZE_PREFERENCE";
export const SET_IS_LOGOUT_OPEN = "SET_IS_LOGOUT_OPEN";
export const SET_SHOW_DATA = "SET_SHOW_DATA";
export const SET_UPLOAD = "SET_UPLOAD";

export interface PreferencesState {
  isMaximized: boolean;
  isLogoutOpen: boolean;
  showData: boolean;
  upload: boolean;
}

interface SetIsMaximizedAction {
  type: typeof SET_MAXIMIZE_PREFERENCE;
  payload: boolean;
}

interface SetIsLogoutOpenAction {
  type: typeof SET_IS_LOGOUT_OPEN;
  payload: boolean;
}

interface SetShowDataAction {
  type: typeof SET_SHOW_DATA;
  payload: boolean;
}

interface SetSetUploadAction {
  type: typeof SET_UPLOAD;
  payload: boolean;
}

export type PreferencesTypeActions =
  | SetIsMaximizedAction
  | SetIsLogoutOpenAction
  | SetShowDataAction
  | SetSetUploadAction;
