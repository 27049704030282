import { Computation, Content, GlobalComputation } from "../../types";

export const SET_UPLOAD_DATA = "SET_UPLOAD_DATA";
export const OUTSIDE_POLLUTEDREGION = "OUTSIDE_POLLUTEDREGION";
export const SET_COMPUTATION_RESULTS = "SET_COMPUTATION_RESULTS";
export const SET_GLOBAL_CONTENT = "SET_GLOBAL_CONTENT";

export interface ContentState {
  content: Content | null;
  outsidePollutedRegion: boolean;
  computation: Computation;
  globalComputation: GlobalComputation | null;
}

interface SetContent {
  type: typeof SET_UPLOAD_DATA;
  payload: Content | null;
}

interface SetOutsidePollutedRegion {
  type: typeof OUTSIDE_POLLUTEDREGION;
  payload: boolean;
}

interface SetComputationResults {
  type: typeof SET_COMPUTATION_RESULTS;
  payload: Computation;
}

interface SetglobalComputation {
  type: typeof SET_GLOBAL_CONTENT;
  payload: any;
}

export type ContentTypes = SetContent | SetOutsidePollutedRegion | SetComputationResults | SetglobalComputation;
