import React from "react";
import { format } from "date-fns";
import ReactTooltip from "react-tooltip";
import Icon from "../icon";
import { Measurements } from "../../types";
import translations from "../../translations/nl.json";
import styles from "./styles.module.scss";

interface Props {
  measurements: Measurements[];
}

const MarkerTable = (props: Props) => {
  const { measurements: markerMeasurements } = props;

  const checkTableContainsJobId = (info: Measurements[]): boolean => {
    let containsJobId = false;
    info.forEach((value: Measurements) => {
      if (value.jobId) {
        containsJobId = true;
      }
    });
    return containsJobId;
  };

  return render();

  function render() {
    const tableContainsJobId = checkTableContainsJobId(markerMeasurements);

    return (
      <table className={styles.markerTable}>
        <thead>
          <tr className={`${styles.tableHeader} ${styles.row}`}>
            <th>{translations.STEALNAMEDATE}</th>
            <th className={styles.depthWrapper}>
              <span className={styles.columnTitle}>{translations.DEPTH}</span>
              <span className={styles.iconWrapper} data-tip="hello world">
                <Icon name="info-circle" />
              </span>
              <ReactTooltip>{translations.DEPTH_INFO}</ReactTooltip>
            </th>
            <th>{translations.PARAMETER}</th>
            <th>{translations.CONCENTRATION}</th>
            <th>{translations.UNIT}</th>
            <th>{translations.MEDIUM}</th>
            <th>{translations.PROFILE_NAME}</th>
            {tableContainsJobId && <th>{translations.JOBID}</th>}
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {markerMeasurements &&
            markerMeasurements.map((info: Measurements, index: number) => {
              return renderRow(info, index, tableContainsJobId);
            })}
        </tbody>
      </table>
    );
  }
  function renderRow(info: Measurements, index: number, tableContainsJobId: boolean) {
    return (
      <tr
        key={`${info.sampleDate}-${index}-${Math.random()}`}
        className={`${styles.row} ${index % 2 === 0 && styles.even}`}
      >
        <td>{format(info.sampleDate, "dd/MM/yyyy")}</td>
        <td>{`${info.top}m - ${info.bottom}m`}</td>
        <td>{info.parameter}</td>
        <td>{info.value}</td>
        <td>{info.unit}</td>
        <td>{info.medium}</td>
        <td>{info.profileName}</td>
        {tableContainsJobId && <td>{info.jobId}</td>}
      </tr>
    );
  }
};

export default MarkerTable;
