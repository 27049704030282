import {
  SET_MAXIMIZE_PREFERENCE,
  SET_IS_LOGOUT_OPEN,
  SET_SHOW_DATA,
  SET_UPLOAD,
  PreferencesState,
  PreferencesTypeActions,
} from "./types";

const initialState: PreferencesState = {
  isMaximized: false,
  isLogoutOpen: false,
  showData: false,
  upload: true,
};

export const preferencesReducer = (state = initialState, action: PreferencesTypeActions) => {
  switch (action.type) {
    case SET_MAXIMIZE_PREFERENCE:
      return {
        ...state,
        isMaximized: action.payload,
      };
    case SET_IS_LOGOUT_OPEN:
      return {
        ...state,
        isLogoutOpen: action.payload,
      };
    case SET_SHOW_DATA:
      return {
        ...state,
        showData: action.payload,
      };
    case SET_UPLOAD:
      return {
        ...state,
        upload: action.payload,
      };
    default:
      return state;
  }
};
