import { SET_MAP_POSITION, SET_LAYER_OPACITY, MapState, MapActionTypes, SET_HISTORICAL_MARKERS } from "./types";

const [initialLat, initialLng, initialZoom] = window._env_
  .REACT_APP_INITIAL_POSITION!.split(",")
  .map((item) => parseFloat(item));

const initialState: MapState = {
  position: {
    lat: initialLat,
    lng: initialLng,
    zoom: initialZoom,
  },
  layerOpacity: 0.5,
  markers: [],
};

export const mapReducer = (state = initialState, action: MapActionTypes): MapState => {
  switch (action.type) {
    case SET_MAP_POSITION:
      return {
        ...state,
        position: action.payload,
      };
    case SET_LAYER_OPACITY:
      return {
        ...state,
        layerOpacity: action.payload,
      };
    case SET_HISTORICAL_MARKERS:
      return {
        ...state,
        markers: action.payload,
      };
    default:
      return state;
  }
};
