import { Location } from "../types";

export function getUserMarkerColor(marker: Partial<Location>) {
  switch (marker.locationType) {
    case "SOIL":
      return "brown";
    case "GROUND_WATER":
      return "#33a3dc";
    case "BOTH":
      return "orange";
    default:
      return "grey";
  }
}
