import { SET_MAXIMIZE_PREFERENCE, SET_SHOW_DATA, SET_UPLOAD, PreferencesTypeActions } from "./types";

export function setIsMaximized(setting: boolean): PreferencesTypeActions {
  return {
    type: SET_MAXIMIZE_PREFERENCE,
    payload: setting,
  };
}

export function setIsLogoutOpen(isOpen: boolean): PreferencesTypeActions {
  return {
    type: SET_MAXIMIZE_PREFERENCE,
    payload: isOpen,
  };
}

export function setShowData(showData: boolean): PreferencesTypeActions {
  return {
    type: SET_SHOW_DATA,
    payload: showData,
  };
}

export function setUpload(upload: boolean): PreferencesTypeActions {
  return {
    type: SET_UPLOAD,
    payload: upload,
  };
}
