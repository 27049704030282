import React, { useReducer, useState } from "react";
import { connect } from "react-redux";
import { History } from "history";
import logo from "../../assets/images/ovam_logo.png";
import Icon from "../../components/icon";
import { auth } from "marvin-auth-kit";
import { loginUser } from "../../redux/auth/actions";
import { setMapPosition } from "../../redux/map/actions";
import { setContent } from "../../redux/content/actions";
import translations from "../../translations/nl.json";
import styles from "./styles.module.scss";

interface IProps {
  history: History;
}

interface DispatchProps {
  loginUser: typeof loginUser;
  setMapPosition: typeof setMapPosition;
  setContent: typeof setContent;
}

type Props = IProps & DispatchProps;

const LoginContainer = ({ history, loginUser, setMapPosition, setContent }: Props) => {
  const [error, setError] = useState(false);
  // If your one element of your state relies on the value of another element of your state, then it's almost always best to use useReducer
  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }), {
    username: "",
    password: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const newValue = event.target.value;
    setUserInput({ [name]: newValue });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { username, password } = userInput;
    try {
      const response = await auth.login(username, password);
      if (response.isOk()) {
        localStorage.setItem("token", response.value.token);
        loginUser(true);
        setContent(null);
        setMapPosition({
          lat: 51.221077490196635,
          lng: 5.33763885498047,
          zoom: 11,
        });
        console.log("+++ OK redirect!!");
        history.push("/analyse");
      } else {
        loginUser(false);
        setError(true);
      }
    } catch (ex) {
      loginUser(false);
      setError(true);
    }
  };

  return (
    <div className={styles.loginWrapper}>
      <div className={styles.credentialsWrapper}>
        <img src={logo} alt="ovam-logo" className={styles.logo} />
        <h1 className={styles.title}>{translations.APP_TITLE}</h1>
        <form className={styles.loginForm} onSubmit={(event) => handleSubmit(event)}>
          <p className={styles.information}>{translations.LOGIN_INFO}</p>
          <div className={styles.inputWrapper}>
            <Icon name="login-user" className={styles.icon} />
            <input
              id="username"
              name="username"
              type="text"
              required
              className={styles.loginInput}
              placeholder={translations.NAME}
              value={userInput.username}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className={styles.inputWrapper}>
            <Icon name="lock" className={styles.icon} />
            <input
              id="password"
              name="password"
              type="password"
              required
              className={styles.loginInput}
              placeholder={translations.PASSWORD}
              value={userInput.password}
              onChange={(e) => handleChange(e)}
            />
            {error && <p className={styles.loginError}>{translations.LOGIN_ERROR}</p>}
          </div>
          <button type="submit" className={styles.loginButton}>
            {translations.SIGN_IN}
          </button>
        </form>
      </div>
      <div className={styles.backgroundImage} />
    </div>
  );
};

const mapDispatchToProps = {
  loginUser,
  setMapPosition,
  setContent,
};

export default connect(null, mapDispatchToProps)(LoginContainer);
