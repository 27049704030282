import React, { useEffect, useState } from "react";
import { persistStore } from "redux-persist";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { LoginRoute, PrivateRoute } from "marvin-auth-kit";
import LoginContainer from "../login.container/login.container";
import AppContainer from "../app.container/app.container";
import { store } from "../../store";

interface Props {
  token?: string | null;
}

interface State {
  isInitialized: boolean;
  isError: boolean;
  error: Error;
}

export const RootContainer = (props: Props) => {
  const [, setState] = useState<State>({
    isInitialized: false,
    isError: false,
    error: new Error(),
  });

  useEffect(() => {
    persistStore(store, {}, async () => {
      try {
        setState((state) => ({ ...state, isInitialized: true }));
      } catch (ex) {
        setState({
          error: ex as Error,
          isError: true,
          isInitialized: false,
        });
      }
    });
  }, []);

  return (
    <Router>
      <Switch>
        <LoginRoute exact path="/login" redirectTo="/analyse" component={LoginContainer} />
        <PrivateRoute redirectTo="/login" path="/" component={AppContainer} />
      </Switch>
    </Router>
  );
};
