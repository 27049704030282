import { isEmpty } from "lodash-es";
import React from "react";
import { ServerError } from "../../types";
import translations from "../../translations/nl.json";
import { parseServerError } from "../../utils/string.utils";
import styles from "./styles.module.scss";

interface Props {
  data: ServerError[];
  hideBodyAndTitle?: boolean;
}
export function WarningList(props: Props) {
  const { data, hideBodyAndTitle } = props;

  return render();

  function render() {
    if (isEmpty(data)) return null;

    return (
      <div>
        {!hideBodyAndTitle && (
          <React.Fragment>
            <h3>{translations.MISSING_RESULTS_TITLE}</h3>
            <p>{translations.MISSING_RESULTS_BODY}</p>
          </React.Fragment>
        )}
        <ul className={styles.warnings}>
          {data.map((warning) => (
            <li className={styles.warningItem}>{parseServerError(warning.message, warning.arguments)}</li>
          ))}
        </ul>
      </div>
    );
  }
}
