/**
 * Replace all replacements with argumentw index positioned
 *
 * Example:
 * parseServerErrror('Er is geen geïnterpoleerde kaart voor {1} in {0}. (lokaal)', ["Grondwater", "Arseen"]);
 *
 * Result: Er is geen geïnterpoleerde kaart voor Arseen in Grondwater. (lokaal).
 *
 * @param message string
 * @param args string[]
 */
export function parseServerError(message: string, args: string[]): string {
  return args.reduce((newMessage: string, arg: string, argIndex: number): string => {
    return newMessage.replaceAll(`{${argIndex}}`, arg);
  }, message);
}
