import { LOGIN_USER, SET_TOKEN, AuthState, AuthtypeActions } from "./types";

const initialState: AuthState = {
  isLoggedIn: false,
  token: null,
};

export const authReducer = (state = initialState, action: AuthtypeActions) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
};
