export const LOGIN_USER = "LOGIN_USER";
export const SET_TOKEN = "SET_TOKEN";
export const LOGOUT_USER = "LOGOUT_USER";

export interface AuthState {
  isLoggedIn: boolean;
  token: string | null;
}

interface LoginUser {
  type: typeof LOGIN_USER;
  payload: boolean;
}

interface SetToken {
  type: typeof SET_TOKEN;
  payload: string | null;
}

interface LogoutUser {
  type: typeof LOGOUT_USER;
}

export type AuthtypeActions = LoginUser | SetToken | LogoutUser;
