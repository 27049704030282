import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store";
import { RootContainer } from "./containers/root.container/root.container";

// This is al needed tot extends Leaflet with the MarkerClusterGroup
import L from "leaflet"; // eslint-disable-line
import Markercluster from "leaflet.markercluster"; // eslint-disable-line
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "./styles/app.scss";

import "normalize.css";
import "./styles/app.scss";

const MOUNT_NODE = document.getElementById("root");

function render() {
  ReactDOM.render(
    <Provider store={store}>
      <RootContainer />
    </Provider>,
    MOUNT_NODE
  );
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
