import React, { Component } from "react";
import { connect, DispatchProp } from "react-redux";
import { Route, Switch, RouteComponentProps } from "react-router-dom";
import classnames from "classnames";
import { RootState, GlobalComputation, Content } from "../../types";
import * as initActions from "../../redux/init/actions";
import * as contentActions from "../../redux/content/actions";
import { getToken, getContent } from "../../selectors";
import MapContainer from "../map.container/map.container";
import AnalysisContainer from "../analysis.container/analysis.container";
import ConfigurationContainer from "../configuration.container/configuation-container";
import LogoutContainer from "../logout.container/logout.container";
import OutAreaContainer from "../outarea.container/outarea.container";
import DataContainer from "../data.container/data.container";
import InAreaContainer from "../inarea.container/inarea.container";
import Preloader from "../../components/preloader/preloader";
import MainNavigation from "../../components/main-navigation/main-navigation";
import { auth } from "marvin-auth-kit";
import logo from "../../assets/images/ovam_logo.png";
import translations from "../../translations/nl.json";
import styles from "./styles.module.scss";
import Pdf from "../../assets/docs/manual.pdf";

interface OwnProps {
  store: any;
}

interface StoreProps {
  content: Content | null;
  isMaximized: boolean;
  token: string | null;
  showData: boolean;
  outsidePollutedRegion: boolean;
  globalComputation: GlobalComputation | null;
}

type Props = OwnProps & DispatchProp & RouteComponentProps & StoreProps;

interface State {
  isInitialized: boolean;
  isError: boolean;
  error: Error;
}

class AppContainer extends Component<Props, State> {
  state = {
    isInitialized: false,
    isError: false,
    error: new Error(),
  };

  async componentDidMount() {
    this.loadInitialData();
  }

  async loadInitialData() {
    try {
      const { history } = this.props;

      try {
        const response = await auth.isAuthenticated;
        if (response.isErr()) {
          throw response.error;
        }
      } catch (ex) {
        history.push("/login");
        contentActions.setContent(null);
        return;
      }

      await this.props.dispatch<any>(initActions.load());
      this.setState({ isInitialized: true });
    } catch (ex) {
      this.setState({
        error: ex,
        isError: true,
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { token } = this.props;
    if (token && prevProps.token !== token) {
      this.setState({ isInitialized: true });
    }
  }

  render() {
    return (
      <Preloader isLoading={!this.state.isInitialized}>
        <React.Fragment>
          {this.state.isError && this.renderError()}
          {this.state.isInitialized && this.renderSuccess()}
        </React.Fragment>
      </Preloader>
    );
  }

  canShowDataContainer() {
    const { location } = this.props;
    if (location.pathname === "/") return false;
    if (location.pathname === "/analyse") return false;
    if (location.pathname === "/instellingen") return false;
    if (location.pathname === "/processing") return false;
    if (location.pathname === "/logout") return false;
    return true;
  }

  renderError() {
    return (
      <div className={styles.errorContainer}>
        <div className={styles.errorMessage}>
          Error while loading...
          <div className={styles.errorDetail}>{this.state.error.toString()}</div>
        </div>
      </div>
    );
  }

  handleNavigationPollutedRegion(inside: boolean) {
    this.props.dispatch(contentActions.setOutsidePollutedRegion(inside));
  }

  renderCustomHeader = () => {
    const { location } = this.props;
    const cxCustomHeader = classnames(styles.customHeader, {
      [styles.isProcessing]: location.pathname === "/processing",
    });
    return (
      <div className={cxCustomHeader}>
        <img src={logo} alt="ovam_logo" />
        <h2>{translations.MAIN_TITLE} </h2>
        <h4>
          <a href={Pdf} target="blank">
            {translations.MANUAL}
          </a>
        </h4>
      </div>
    );
  };

  renderSuccess() {
    const { content, isMaximized, showData, location, outsidePollutedRegion, globalComputation } = this.props;
    return (
      <>
        {this.renderCustomHeader()}
        <div className={styles.container}>
          {!isMaximized && (
            <MainNavigation
              content={content}
              globalComputation={globalComputation}
              outsidePollutedRegion={outsidePollutedRegion}
              setOutsidePollutedRegion={(inside: boolean) => this.handleNavigationPollutedRegion(inside)}
            />
          )}
          <div className={styles.panels}>
            <MapContainer location={location} />
            {this.canShowDataContainer() && showData && <DataContainer />}
            {!isMaximized && (
              <Switch>
                <Route path="/analyse" component={AnalysisContainer} />
                <Route path="/instellingen" component={ConfigurationContainer} />
                <Route path="/result" component={InAreaContainer} />
              </Switch>
            )}
          </div>
        </div>
        {outsidePollutedRegion && <OutAreaContainer />}
        {!isMaximized && <Route path="/logout" component={LogoutContainer} />}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    content: getContent(state),
    isMaximized: state.preferences.isMaximized,
    token: getToken(state),
    showData: state.preferences.showData,
    outsidePollutedRegion: state.content.outsidePollutedRegion,
    globalComputation: state.content.globalComputation,
  };
};

export default connect(mapStateToProps)(AppContainer);
