import axios from "axios";
import { getCommonHeaders, processHeaders } from "./service.utils";
import { Location, LocationWithMeasurements } from "../types";

export async function getMarkerLocations(token: string | null): Promise<Location[]> {
  const config = getCommonHeaders(token);
  const API_PATH = window._env_.REACT_APP_API_PATH;
  const response = await axios.get(`${API_PATH}/locations`, config);
  processHeaders(response.headers);
  return response.data;
}

export async function getLocationMeasurements(
  locactionId: number,
  token: string | null
): Promise<LocationWithMeasurements> {
  const config = getCommonHeaders(token);
  const API_PATH = window._env_.REACT_APP_API_PATH;
  const response = await axios.get(`${API_PATH}/locations/${locactionId}`, config);
  processHeaders(response.headers);
  return response.data;
}
