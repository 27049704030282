import React, { useMemo } from "react";
import { PlotData } from "plotly.js";
import Plot from "react-plotly.js";
import { Boxplot as IBoxplot } from "../../types";

interface Props {
  boxplotValues: IBoxplot;
  unit: string;
  value: string;
}

const Boxplot = ({ boxplotValues, unit, value }: Props) => {
  const green = "#a8bd46";
  const imageSource = () => {
    if (boxplotValues.color === "GREEN") return `${process.env.PUBLIC_URL}/images/icons/green-star.svg`;
    else if (boxplotValues.color === "RED") return `${process.env.PUBLIC_URL}/images/icons/red-star.svg`;
    else return `${process.env.PUBLIC_URL}/images/icons/orange-star.svg`;
  };

  const data = useMemo(() => {
    const values: Partial<PlotData> = {
      name: value,
      y: [
        boxplotValues.lowerWhisker,
        boxplotValues.lowerQuartile,
        boxplotValues.lowerQuartile,
        boxplotValues.median,
        boxplotValues.upperQuartile,
        boxplotValues.upperQuartile,
        boxplotValues.upperWhisker,
      ],
      type: "box",
      marker: {
        color: green,
      },
      // @ts-ignore
      boxpoints: boxplotValues ? boxplotValues.value : "",
      fillcolor: "cls",
    };

    return [values];
  }, [boxplotValues, value]);

  return render();

  function render() {
    return (
      <Plot
        data={data}
        layout={{
          width: 300,
          showlegend: false,
          hovermode: false,
          yaxis: {
            title: unit,
          },
          images: [
            {
              source: imageSource(),
              layer: "above",
              xref: "x",
              yref: "y",
              x: 0,
              y: boxplotValues.value,
              sizex: (boxplotValues.upperWhisker - boxplotValues.lowerWhisker) * 0.08,
              sizey: (boxplotValues.upperWhisker - boxplotValues.lowerWhisker) * 0.08,
              xanchor: "center",
              yanchor: "middle",
            },
          ],
        }}
      ></Plot>
    );
  }
};

export default Boxplot;
