import { RootState, Baselayer, Location, Content, Computation, RawLayer } from "../types";
import { WMSTileLayerProps } from "react-leaflet";
import { overlayers } from "../config/overlayers";

export const getBaselayers = (state: RootState): Baselayer[] => {
  return state.layers.baselayers;
};

export const getWmsLayer = (): WMSTileLayerProps[] => {
  return overlayers.map((overlayer) => overlayer.wmsLayer);
};

export const getActiveOverlayers = (state: RootState): WMSTileLayerProps[] => {
  return state.layers.activeOverlayers;
};

export const getOverlayers = (): RawLayer[] => {
  return overlayers;
};

export const getToken = (state: RootState): string | null => {
  return state.auth.token;
};

export const getLayerOpacity = (state: RootState): number => {
  return state.map.layerOpacity;
};

export const getContent = (state: RootState): Content | null => {
  return state.content.content;
};

export const getComputation = (state: RootState): Computation => {
  return state.content.computation;
};

export const getMarkers = (state: RootState): Location[] => {
  return state.map.markers;
};

export const getUpload = (state: RootState): boolean => {
  return state.preferences.upload;
};
