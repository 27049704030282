import React, { useEffect } from "react";
import { useLeaflet } from "react-leaflet";
import * as L from "leaflet";
import { divIcon } from "leaflet";
import { Location, MapPosition } from "../../types";
import historicalMarker from "../../assets/images/historical-marker.svg";
import historicalMarkerSoil from "../../assets/images/historical-marker-soil.svg";
import historicalMarkerGroundWater from "../../assets/images/historical-marker-ground-water.svg";
import historicalMarkerBoth from "../../assets/images/historical-marker-both.svg";

interface Props {
  markers: Location[];
  updateMapPosition: (position: MapPosition) => void;
  handleHistoricalMarkerClick: (id: number, lat: number, lng: number) => Promise<void>;
}
const HistoricalMarkers = (props: Props) => {
  const { markers, handleHistoricalMarkerClick, updateMapPosition } = props;

  // IMPORTANT: this unorthodox way of rendering clusters in a MarkerCluster without using React components
  //  is because the naive implementation with Leaflet React components was way to slow to render
  //  at the time Jens Decraecker found this solution to fit best.
  const leafletCtx = useLeaflet();
  useEffect(() => {
    const cluster = new L.MarkerClusterGroup({
      //@ts-ignore
      disableClusteringAtZoom: 18,
      spiderfyOnMaxZoom: false,
      iconCreateFunction: function (cluster: any) {
        return divIcon({
          html: `<span>${cluster.getChildCount()}</span>`,
          className: "historical-marker-cluster-custom",
        });
      },
    });

    cluster.on("clusterclick", function (a: any) {
      updateMapPosition({
        lat: a.latlng.lat,
        lng: a.latlng.lng,
        zoom: a.layer._zoom,
      });
    });

    const markerList = markers.map((marker) => {
      const { id, location_4326 } = marker;
      const [lng, lat] = location_4326.coordinates;
      return L.marker(L.latLng(lat, lng), {
        riseOnHover: true,
        icon: L.icon({
          iconUrl: getIconUrl(marker),
          iconSize: [10, 20],
        }),
      }).on("click", function () {
        handleHistoricalMarkerClick(id, lat, lng);
      });
    }, []);

    cluster.addLayers(markerList);
    leafletCtx.map && leafletCtx.map.addLayer(cluster);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <div />;
};

function getIconUrl(marker: Location) {
  switch (marker.locationType) {
    case "SOIL":
      return historicalMarkerSoil;
    case "GROUND_WATER":
      return historicalMarkerGroundWater;
    case "BOTH":
      return historicalMarkerBoth;
    default:
      return historicalMarker;
  }
}

export default HistoricalMarkers;
