import React, { useState } from "react";
import { WMSTileLayerProps } from "react-leaflet";
import { MapControlButton, MapControl, Window, CheckBox, Tooltip } from "marvin-ui-kit";
import Icon from "../icon";
import { RawLayer } from "../../types";
import translations from "../../translations/nl.json";
import styles from "./styles.module.scss";

interface Props {
  allOverlayers: WMSTileLayerProps[];
  overlayers: RawLayer[];
  setActiveOverlayer: (layer: WMSTileLayerProps) => void;
  removeActiveOverlayers: () => void;
}

export const LayersMapControl = ({
  allOverlayers: activeOverlayers,
  overlayers,
  setActiveOverlayer,
  removeActiveOverlayers,
}: Props) => {
  const [isOverlayersVisible, setIsOverlayersVisible] = useState(false);

  // TODO: the ativer layer management should be done better. Not based on wmsLayer names, but on simple id reference with RawLayer as the common type.
  const activeOverlayersNames = activeOverlayers.map((overlayer) => overlayer.layers);

  const toggle = () => setIsOverlayersVisible(!isOverlayersVisible);

  const handleIsActive = (layer: RawLayer): void => {
    if (layer.wmsLayer.layers === "zmkempen:cirkels") return;
    if (activeOverlayersNames.indexOf(layer.wmsLayer.layers) !== -1) {
      removeActiveOverlayers();
    } else {
      setActiveOverlayer(layer.wmsLayer);
    }
  };

  if (isOverlayersVisible) {
    return (
      <MapControl className="mapControlController">
        <Window title={translations.OVERLAYERS} onClose={toggle}>
          {overlayers.map((overlayer: RawLayer) => {
            return renderLayer(overlayer);
          })}
        </Window>
      </MapControl>
    );
  } else {
    return (
      <Tooltip text={translations.MAP} position="left">
        <MapControlButton onClick={toggle} disabled={overlayers.length === 0} size="large">
          <Icon name="layers" />
        </MapControlButton>
      </Tooltip>
    );
  }

  function renderLayer(overlayer: RawLayer) {
    const activeOverlayersNames = activeOverlayers.map((overlayer) => overlayer.layers);

    const checkboxState = () => {
      if (overlayer.wmsLayer.layers && activeOverlayersNames.includes(overlayer.wmsLayer.layers)) {
        return "checked";
      }
      return "unchecked";
    };

    return (
      <div
        key={overlayer.wmsLayer.layers}
        onClick={() => handleIsActive(overlayer)}
        className={styles.overlayerWrapper}
      >
        <CheckBox status={checkboxState()} checkedState={checkedSvg} />
        <p>{overlayer.name}</p>
      </div>
    );
  }
};

const checkedSvg = (
  <svg width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <clipPath id="clip-path">
        <rect width="16" height="16" fill="none" />
      </clipPath>
    </defs>
    <g id="element_checkbox_checked" clipPath="url(#clip-path)">
      <path
        id="check-square"
        d="M14.286,48H1.714A1.714,1.714,0,0,1,0,46.286V33.714A1.714,1.714,0,0,1,1.714,32H14.286A1.714,1.714,0,0,1,16,33.714V46.286A1.714,1.714,0,0,1,14.286,48Zm-7.31-3.5,6.571-6.571a.571.571,0,0,0,0-.808l-.808-.808a.571.571,0,0,0-.808,0L6.571,41.669l-2.5-2.5a.571.571,0,0,0-.808,0l-.808.808a.571.571,0,0,0,0,.808L6.167,44.5a.571.571,0,0,0,.808,0Z"
        transform="translate(0 -32)"
        fill="#a8bd46"
      />
    </g>
  </svg>
);
