import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, Route } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { History } from "history";
import Icon from "../../components/icon";
import { RootState, ServerError } from "../../types";
import { getToken, getUpload } from "../../selectors";
import { uploadXml, calculateComputation, trackProgress, getAllEndResults } from "../../services/computation.service";
import { setContent, setOutsidePollutedRegion, setglobalComputation } from "../../redux/content/actions";
import { setUpload } from "../../redux/preferences/actions";
import translations from "../../translations/nl.json";
import styles from "./styles.module.scss";
import { Processing } from "../../components/processing/processing";
import { parseServerError } from "../../utils/string.utils";
import { getErrors } from "../../services/errors.service";

interface IProps {
  history: History;
}

interface StoreProps {
  token: string | null;
  upload: boolean;
}

interface DispatchProps {
  setContent: typeof setContent;
  setUpload: typeof setUpload;
  setOutsidePollutedRegion: typeof setOutsidePollutedRegion;
  setglobalComputation: typeof setglobalComputation;
}

type Props = IProps & StoreProps & DispatchProps;

const AnalysisContainer = ({
  history,
  token,
  upload,
  setContent,
  setUpload,
  setOutsidePollutedRegion,
  setglobalComputation,
}: Props) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleChange,
    accept: ".xml",
  });
  const [uploadErrors, setUploadErrors] = useState<ServerError[]>([]);

  const trackProgressPromise = (computationId: number, token: string | null) => {
    return new Promise(async (resolve, reject) => {
      const trackResponse = await trackProgress(computationId, token);
      if (trackResponse.errors.length > 0) throw trackResponse.errors;
      if (trackResponse.status === "FINISHED") resolve(true);
      if (trackResponse.status === "COMPUTING") {
        setTimeout(async () => {
          try {
            await trackProgressPromise(computationId, token);
            resolve(true);
          } catch (ex) {
            setUpload(false);
            reject(ex);
          }
        }, 100);
      }
    });
  };

  const getAllResults = async (computationId: number) => {
    const summaryResponse = await getAllEndResults(computationId, token);
    setglobalComputation(summaryResponse);
  };

  const translateErrors = async (errorList: ServerError[]) => {
    const translatedErrors = await getErrors(token, errorList);
    return translatedErrors;
  };

  async function handleChange(event: any) {
    try {
      setOutsidePollutedRegion(false);
      setglobalComputation(null);
      history.push("/analyse/processing");
      const xmlResponse = await uploadXml(event[0], token);
      console.log("handleChange -> xmlResponse", xmlResponse);
      setContent(xmlResponse ? xmlResponse : null);

      if (xmlResponse.insidePollutedRegion) {
        await calculateComputation(xmlResponse.computationId, token);
        await trackProgressPromise(xmlResponse.computationId, token);

        await getAllResults(xmlResponse.computationId);

        history.push("/result");
      } else {
        setOutsidePollutedRegion(true);
        history.push("/");
      }
    } catch (ex) {
      console.log("ex", ex.response.data);
      const translatedErrors = await translateErrors(ex.response.data);
      const errorsWithArguments = ex.response.data.map((error: ServerError, index: number) => {
        return { ...error, message: translatedErrors[index].translation };
      });
      setUploadErrors(errorsWithArguments);
      history.push("/analyse");
      setUpload(false);
    }
  }

  const renderUploadFile = () => {
    return (
      <>
        <p className={styles.uploadInfo}>{translations.START_ANALYSIS_INFO}</p>
        <div {...getRootProps()}>
          <div className={styles.uploadWrapper}>
            <Icon name="upload" className={styles.uploadIcon} />
            <p>{translations.INPUT_TEXT}</p>
            <input {...getInputProps()} />
          </div>
        </div>
      </>
    );
  };

  const renderUploadError = () => {
    return (
      <>
        <div className={styles.titleWrapper}>
          <Icon name="exclamation-circle" className={styles.errorIcon} />
          <p className={`${styles.errorTitle} ${styles.errorText}`}>{translations.ERROR}</p>
        </div>
        <div className={`${styles.errorInfo} ${styles.errorText}`}>
          {uploadErrors.map((uploadError, index) => (
            <div key={index}>{parseServerError(uploadError.message, uploadError.arguments)}</div>
          ))}
          {translations.ANALYSIS_ERROR}
        </div>
        <p onClick={() => setUpload(true)} className={styles.tryAgain}>
          {translations.AGAIN}
        </p>
      </>
    );
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.headerTitle}>{translations.START_ANALYSE}</h1>
        </div>
        <div className={styles.body}>
          <Link to={"/"} className={styles.buttonClose}>
            <Icon name="times" />
          </Link>
          {upload ? renderUploadFile() : renderUploadError()}
        </div>
      </div>
      <Route path="/analyse/processing" component={Processing} />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    token: getToken(state),
    upload: getUpload(state),
  };
};

const mapDispatchToProps = {
  setContent,
  setUpload,
  setOutsidePollutedRegion,
  setglobalComputation,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisContainer);
