import React, { Component } from "react";
// @ts-ignore
import * as KeyboardEventHandler from "react-keyboard-event-handler";
import classNames from "classnames";
import Icon from "../icon";
import { getLocation, Location } from "../../services/geopunt.service";
import translations from "../../translations/nl.json";
import styles from "./styles.module.scss";
import { MapControlButton, Tooltip } from "marvin-ui-kit";
import { createPortal } from "react-dom";

interface IProps {
  className: string;
  onSearchResultClick: any;
}

interface IState {
  searchQuery: string;
  searchTimeout: boolean;
  searchResults: any;
  selectedResultIndex: number;
  isOpen: boolean;
  isSearchOpen: boolean;
  activeSearchOption: "address" | "coordinates";
}

class SearchBar extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      searchQuery: "",
      searchTimeout: false,
      searchResults: [],
      selectedResultIndex: -1,
      isOpen: false,
      isSearchOpen: false,
      activeSearchOption: "address",
    };
  }

  handleSearchQueryChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    this.setState({
      searchQuery: value,
      isOpen: true,
      selectedResultIndex: 0,
    });
    if (!value) return;

    this.searchResults(value);
  }

  async searchResults(searchQuery: string) {
    const { activeSearchOption } = this.state;

    this.setState({
      searchTimeout: true,
    });
    const searchResults = await getLocation(searchQuery, activeSearchOption);
    this.setState({
      searchResults,
      searchTimeout: false,
    });
  }

  handleSearchResultClick(searchResult: Location) {
    this.setState({
      isOpen: false,
      searchQuery: searchResult.displayName,
    });
    this.props.onSearchResultClick(searchResult);
  }

  clearSearch() {
    this.setState({ searchQuery: "", searchResults: [] });
  }

  handleKeyEvent(key: string, e: React.KeyboardEvent) {
    e.preventDefault();
    switch (key) {
      case "down":
        this.selectNextResult();
        break;
      case "up":
        this.selectPreviousResult();
        break;
      case "enter":
        this.selectCurrentResult();
        break;
      case "esc":
        this.clearSearch();
        break;
      default:
        break;
    }
  }

  selectNextResult() {
    const { searchResults, isOpen, selectedResultIndex } = this.state;
    if (!searchResults) return;

    // if results are not visibile, only show the results without going to the next
    if (!isOpen) {
      this.setState({ isOpen: true });
      return;
    }

    const currentIndex = selectedResultIndex;
    let nextIndex = currentIndex + 1;
    if (currentIndex + 1 === searchResults.length) nextIndex = -1;

    this.setState({ selectedResultIndex: nextIndex });
  }

  selectPreviousResult() {
    const { isOpen, searchResults, selectedResultIndex } = this.state;
    if (!searchResults) return;

    // if results are not visibile, only show the results without going to the next
    if (isOpen === false) {
      this.setState({ isOpen: true });
      return;
    }

    const currentIndex = selectedResultIndex;
    let previousIndex = currentIndex - 1;
    if (currentIndex - 1 < -1) previousIndex = searchResults.length - 1;

    this.setState({ selectedResultIndex: previousIndex });
  }

  selectCurrentResult() {
    const { searchResults, selectedResultIndex } = this.state;
    if (searchResults.length === 0) return;
    if (selectedResultIndex === -1) {
      this.setState({ selectedResultIndex: 0 });
    }
    const currentSearchResult = searchResults[selectedResultIndex];
    this.handleSearchResultClick(currentSearchResult);
  }

  setActiveSearchOption(option: "address" | "coordinates") {
    this.setState({ activeSearchOption: option });
    this.clearSearch();
  }

  render() {
    const { isSearchOpen } = this.state;

    return <div className={styles.wrapper}>{isSearchOpen ? this.renderOpen() : this.renderClosed()}</div>;
  }

  renderOpen() {
    const { className } = this.props;
    const { searchResults, selectedResultIndex, isOpen, searchQuery, activeSearchOption, searchTimeout } = this.state;

    const addressOption = activeSearchOption === "address";
    const coordinatesOption = activeSearchOption === "coordinates";
    const placeholderText = () => {
      if (coordinatesOption) return translations.SEARCHBAR_PLACEHOLDER_COORDINATES;
      else return translations.SEARCHBAR_PLACEHOLDER;
    };
    return createPortal(
      <div className={styles.container}>
        <div className={styles.searchOptions}>
          <div onClick={() => this.setActiveSearchOption("address")} className={styles.option}>
            <p>{translations.ADDRESS}</p>
            {addressOption && <div className={`${addressOption && styles.animated} ${styles.borderBottom}`} />}
          </div>
          <div onClick={() => this.setActiveSearchOption("coordinates")} className={styles.option}>
            <p>{translations.COORDINATES}</p>
            {coordinatesOption && <div className={`${coordinatesOption && styles.animated} ${styles.borderBottom}`} />}
          </div>
          <span className={styles.buttonClose} onClick={() => this.setState({ isSearchOpen: false })}>
            <Icon name="times" />
          </span>
        </div>
        <div className={`${styles.searchBoxWrapper} ${className}`}>
          <KeyboardEventHandler handleKeys={["up", "down", "enter", "esc"]} onKeyEvent={this.handleKeyEvent.bind(this)}>
            <div className={styles.searchBox}>
              <input
                className={styles.searchInput}
                type="input"
                placeholder={placeholderText()}
                value={searchQuery}
                onFocus={() => this.setState({ isOpen: true })}
                onChange={(event) => this.handleSearchQueryChange(event)}
              />
              {searchQuery && (
                <Icon
                  name={searchTimeout ? "processing" : "times"}
                  className={`icon-close ${styles.inputIcon} ${searchTimeout && styles.animated}`}
                  onClick={() => this.clearSearch()}
                />
              )}
              <Icon name="search" className={`icon-search ${styles.inputIcon}`} />
            </div>
          </KeyboardEventHandler>

          <div className={styles.searchResults}>
            {isOpen &&
              searchResults.map((searchResult: Location, index: number) => {
                const cx = classNames(styles.searchResultItem, {
                  [styles.selected]: selectedResultIndex === index,
                });
                return (
                  <div key={searchResult.id} className={cx} onClick={() => this.handleSearchResultClick(searchResult)}>
                    <Icon name="map-marker-alt" className={styles.searchMarker} />
                    <div className={styles.resultText}>{searchResult.displayName}</div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>,
      document.body
    );
  }

  renderClosed() {
    return (
      <Tooltip text={translations.SEARCH} position="left">
        <MapControlButton onClick={() => this.setState({ isSearchOpen: true })} size="large">
          <Icon name="search" />
        </MapControlButton>
      </Tooltip>
    );
  }
}

export default SearchBar;
