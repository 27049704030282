import React from "react";
import Icon from "../../components/icon";
import translations from "../../translations/nl.json";
import styles from "./styles.module.scss";

export const Processing = () => {
  return (
    <div className={styles.processingWrapper}>
      <Icon className={styles.processingIcon} name="processing" />
      <h2 className={styles.processingHeader}>{translations.PROCESSING}</h2>
    </div>
  );
};
