import {
  SET_BASELAYER,
  SET_ACTIVE_OVERLAYERS,
  REMOVE_ACTIVE_OVERLAYERS,
  ADD_BASELAYERS,
  LayersActionTypes,
} from "./types";
import { Baselayer } from "../../types";
import { WMSTileLayerProps } from "react-leaflet";

export function addBaselayers(baseLayers: Baselayer[]): LayersActionTypes {
  return {
    type: ADD_BASELAYERS,
    payload: baseLayers,
  };
}

export function setBaselayer(layer: Baselayer): LayersActionTypes {
  return {
    type: SET_BASELAYER,
    payload: layer,
  };
}

export function setActiveOverlayers(layer: WMSTileLayerProps): LayersActionTypes {
  return {
    type: SET_ACTIVE_OVERLAYERS,
    payload: layer,
  };
}

export function removeActiveOverlayers(): LayersActionTypes {
  return {
    type: REMOVE_ACTIVE_OVERLAYERS,
  };
}
