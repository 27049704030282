import axios from "axios";

const baseUrl = "https://geo.api.vlaanderen.be/geolocation/v4";

interface getLocationOptions {
  count: number;
}

interface Side {
  lat: number;
  lng: number;
}

export interface Location {
  id: string;
  lat: number;
  lng: number;
  displayName: string;
  municipality: string;
  bbox: {
    lowerLeft: Side;
    upperRight: Side;
  };
}

export async function getLocation(
  query: string,
  activeSearchOption: "address" | "coordinates",
  customOptions?: getLocationOptions
): Promise<Location[]> {
  const addressOption = activeSearchOption === "address";
  const coordinatesOption = activeSearchOption === "coordinates";

  try {
    const url = `${baseUrl}/Location`;
    const defaultOptions = { count: 5 };
    const options = { ...defaultOptions, ...customOptions };

    const trimmedQuery = query.replace(/\s/g, "");

    const config = {
      params: {
        q: addressOption ? query : "",
        c: options.count,
        type: "Thoroughfarename",
        xy: coordinatesOption ? trimmedQuery : "",
      },
    };
    const response = await axios(url, config);
    const formattedResponse = response.data.LocationResult.map(
      (location: any): Location => {
        return {
          id: location.ID,
          lat: location.Location.Lat_WGS84,
          lng: location.Location.Lon_WGS84,
          displayName: location.FormattedAddress,
          municipality: location.Municipality,
          bbox: {
            lowerLeft: {
              lat: location.BoundingBox.LowerLeft.Lat_WGS84,
              lng: location.BoundingBox.LowerLeft.Lon_WGS84,
            },
            upperRight: {
              lat: location.BoundingBox.UpperRight.Lat_WGS84,
              lng: location.BoundingBox.UpperRight.Lon_WGS84,
            },
          },
        };
      }
    );
    return formattedResponse;
  } catch (error) {
    throw error;
  }
}
