import { store } from "../store";

export function getCommonHeaders(token: string | null) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return config;
}

export function processHeaders(headers: any) {
  store.dispatch({
    type: "SET_TOKEN",
    payload: headers.token,
  });
}
