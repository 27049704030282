import React, { useState } from "react";
import { setShowData } from "../../redux/preferences/actions";
import Icon from "../icon";
import { Content, GlobalComputation, Report } from "../../types";
import { getEndResult } from "../../services/computation.service";
import translations from "../../translations/nl.json";
import styles from "./styles.module.scss";

interface Props {
  content: Content | null;
  setShowData: typeof setShowData;
  setComputationResults: (response: any) => void;
  globalComputation: GlobalComputation;
  token: string | null;
}

const MediumsAndParameters = ({ content, globalComputation, setShowData, setComputationResults, token }: Props) => {
  const [noData, setNoData] = useState(false);
  const handleClick = async (mediumId: string, parameterId: string) => {
    try {
      if (content) {
        const response = await getEndResult(content.computationId, mediumId, parameterId, token);
        setComputationResults(response);
        setShowData(true);
      } else {
        setNoData(true);
      }
    } catch (ex) {
      throw Error(ex);
    }
  };

  const remappedglobalComputation = globalComputation.reports.map((item: Report) => {
    return {
      medium: item.medium,
      parameter: item.parameter,
      localColor: item.localBoxplot ? item.localBoxplot?.color : "",
      regionalColor: item.regionalBoxplot?.color ? item.regionalBoxplot.color : "",
      summaryColor: item.summaryColor,
    };
  });

  const renderRow = (item: any, index: number) => {
    return (
      <tr key={`${item.medium.description}-${index}`}>
        <td>{item.medium.description}</td>
        <td>{item.parameter.description}</td>
        <td className={styles.iconWrapper}>
          {item.localColor && <Icon name="star" fill={item.localColor} size={20} />}
        </td>
        <td>{item.regionalColor && <Icon name="star" fill={item.regionalColor} size={20} />}</td>
        <td>{item.summaryColor && <Icon name="star" fill={item.summaryColor} size={20} />}</td>
        <td className={styles.details} onClick={() => handleClick(item.medium.id, item.parameter.id)}>
          {translations.DETAILS}
        </td>
      </tr>
    );
  };

  return (
    <table>
      <thead>
        <tr>
          <th>{translations.MEDIUM_LOWER}</th>
          <th>{translations.PARAMETER_LOWER}</th>
          <th>{translations.LOCAL}</th>
          <th>{translations.REGIONAL}</th>
          <th>{translations.ENDRESULT_LOWER}</th>
          <th></th>
        </tr>
      </thead>
      {(remappedglobalComputation.length < 1 || noData) && (
        <tr>
          <td colSpan={6}>{translations.NO_DATA}</td>
        </tr>
      )}
      <tbody>
        {remappedglobalComputation.map((item: any, index: number) => {
          return renderRow(item, index);
        })}
      </tbody>
    </table>
  );
};

export default MediumsAndParameters;
