import {
  SET_UPLOAD_DATA,
  OUTSIDE_POLLUTEDREGION,
  ContentState,
  ContentTypes,
  SET_COMPUTATION_RESULTS,
  SET_GLOBAL_CONTENT,
} from "./types";

// TODO: this way of setting the initial state to all 0 values is bad.
//  better would be to allow certain content to be `null`
//  Hard to refactor quickly, do this when time is present!
const initialState: ContentState = {
  content: {
    computationId: 0,
    insidePollutedRegion: false,
    locations: [],
    mediumsAndParameters: [
      {
        medium: {
          id: "",
          description: "",
        },
        parameters: [
          {
            id: "",
            description: "",
          },
        ],
      },
    ],
    parcels: [],
  },
  outsidePollutedRegion: false,
  computation: {
    computationId: 0,
    owner: "",
    report: {
      unit: "",
      localNbMeasurements: 0,
      localBoxplot: {
        color: "GREEN",
        lowerQuartile: 0,
        lowerWhisker: 0,
        median: 0,
        upperQuartile: 0,
        upperWhisker: 0,
        value: 0,
      },
      localWarnings: [],
      regionalNbMeasurements: 0,
      regionalBoxplot: {
        color: "GREEN",
        lowerQuartile: 0,
        lowerWhisker: 0,
        median: 0,
        upperQuartile: 0,
        upperWhisker: 0,
        value: 0,
      },
      regionalWarnings: [],
      medium: {
        id: "",
        description: "",
      },
      parameter: {
        id: "",
        description: "",
      },
      summaryColor: "GREEN",
    },
    warnings: [],
  },
  globalComputation: null,
};

export const contentReducer = (state = initialState, action: ContentTypes) => {
  switch (action.type) {
    case SET_UPLOAD_DATA:
      return {
        ...state,
        content: action.payload,
        medium: null,
      };
    case OUTSIDE_POLLUTEDREGION:
      return {
        ...state,
        outsidePollutedRegion: action.payload,
      };
    case SET_COMPUTATION_RESULTS:
      return {
        ...state,
        computation: action.payload,
      };
    case SET_GLOBAL_CONTENT:
      return {
        ...state,
        globalComputation: action.payload,
      };
    default:
      return state;
  }
};
