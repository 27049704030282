import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Icon from "../../components/icon";
import ChartWrapper from "../../components/chart-wrapper/chart-wrapper";
import InformationTable from "../../components/information-table/information-table";
import { setShowData } from "../../redux/preferences/actions";
import { RootState, Content, Computation } from "../../types";
import { getComputation, getContent } from "../../selectors";
import styles from "./styles.module.scss";
import { WarningList } from "../../components/warning-list/warning-list";

interface IProps {}

interface StoreProps {
  content: Content | null;
  computation: Computation;
}

interface DispatchProps {
  setShowData: typeof setShowData;
}

type Props = IProps & StoreProps & DispatchProps;

const DataContainer = (props: Props) => {
  const { setShowData, computation } = props;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1
          className={styles.headerTitle}
        >{`${computation.report.medium.description}-${computation.report.parameter.description} `}</h1>
      </div>
      <div className={styles.body}>
        <Link to={"/result"} className={styles.buttonClose} onClick={() => setShowData(false)}>
          <Icon name="times" />
        </Link>
        <ChartWrapper computation={computation} />
        <InformationTable computation={computation} />
        <div className={styles.warningList}>
          <WarningList
            data={[...computation.report.localWarnings, ...computation.report.regionalWarnings]}
            hideBodyAndTitle={true}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    content: getContent(state),
    computation: getComputation(state),
  };
};

const mapDispatchToProps = {
  setShowData,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataContainer);
