import React from "react";
import classnames from "classnames";
import styles from "./styles.module.scss";

interface Props {
  isLoading: boolean;
  children: JSX.Element;
}

const Preloader = (props: Props) => {
  const cxPreloader = classnames(styles.preloader, {
    [styles.active]: props.isLoading,
  });
  return (
    <React.Fragment>
      <div className={cxPreloader}>
        <div className={styles.message}>Loading data...</div>
      </div>
      {props.children}
    </React.Fragment>
  );
};

export default Preloader;
