import React from "react";
import { Popup as LeafletPopup } from "react-leaflet";
import MarkerTable from "../marker-table/marker-table";
import { Measurements } from "../../types";

interface Props {
  markerLocation: number[];
  markerMeasurements: Measurements[];
  markerType: "historical" | "user" | string;
}

const Popup = React.memo(({ markerLocation, markerType, markerMeasurements }: Props) => {
  return (
    <LeafletPopup
      key={`${markerLocation.toString()}-${Math.random()}`}
      position={[markerLocation[0], markerLocation[1]]}
      className={markerType === "historical" ? "historicalMarker" : "userMarker"}
    >
      <MarkerTable measurements={markerMeasurements} />
    </LeafletPopup>
  );
});

export default Popup;
