import { RawLayer } from "../types";

const geoserverUrl = `${window._env_.REACT_APP_GEOSERVER_URL}${window._env_.REACT_APP_GEOSERVER_PATH}`;
const guppyUrl = `${window._env_.REACT_APP_GUPPY_URL}${window._env_.REACT_APP_GUPPY_PATH}`;

export const overlayers: RawLayer[] = [
  {
    name: "wijde omgeving",
    wmsLayer: {
      url: geoserverUrl,
      layers: "zmkempen:cirkels",
      minZoom: 0,
      maxZoom: 18,
      styles: "",
      opacity: 1,
      transparent: true,
      format: "image/png",
    },
  },
  {
    name: "zinkaswegen ",
    wmsLayer: {
      url: geoserverUrl,
      layers: "zmkempen:zinkaswegen",
      name: "Gekende zinkaswegen",
      minZoom: 0,
      maxZoom: 18,
      styles: "",
      opacity: 1,
      transparent: true,
      format: "image/png",
    },
  },
  {
    name: "cd bodem",
    wmsLayer: {
      url: guppyUrl,
      name: "cd bodem",
      layers: "zmkempen:zmkempen_cd-bodem_v1",
      minZoom: 0,
      maxZoom: 18,
      styles: "cd-bodem",
      opacity: 1,
      transparent: true,
      format: "image/png",
    },
  },
  {
    name: "pb bodem",
    wmsLayer: {
      url: guppyUrl,
      name: "pb bodem",
      layers: "zmkempen:zmkempen_pb-bodem_v1",
      minZoom: 0,
      maxZoom: 18,
      styles: "pb-bodem",
      opacity: 1,
      transparent: true,
      format: "image/png",
    },
  },
  {
    name: "zn bodem",
    wmsLayer: {
      url: guppyUrl,
      name: "zn bodem",
      layers: "zmkempen:zmkempen_zn-bodem_v1",
      minZoom: 0,
      maxZoom: 18,
      styles: "zn-bodem",
      opacity: 1,
      transparent: true,
      format: "image/png",
    },
  },
  {
    name: "cd grondwater",
    wmsLayer: {
      url: guppyUrl,
      name: "cd grondwater",
      layers: "zmkempen:zmkempen_cd-grondwater_v1",
      minZoom: 0,
      maxZoom: 18,
      styles: "cd-grondwater",
      opacity: 1,
      transparent: true,
      format: "image/png",
    },
  },
  {
    name: "zn grondwater",
    wmsLayer: {
      url: guppyUrl,
      name: "zn grondwater",
      layers: "zmkempen:zmkempen_zn-grondwater_v1",
      minZoom: 0,
      maxZoom: 18,
      styles: "zn-grondwater",
      opacity: 1,
      transparent: true,
      format: "image/png",
    },
  },
];
