import { Dispatch } from "redux";
import { RootState } from "../../types";
import * as LayerActions from "./../layers/actions";
import { baselayers } from "../../config/baselayers";

// load initial application data
export function load() {
  return (dispatch: Dispatch, getState: () => RootState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(LayerActions.addBaselayers(baselayers));
        resolve();
      } catch (ex) {
        reject(ex);
      }
    });
  };
}
