import {
  SET_UPLOAD_DATA,
  ContentTypes,
  SET_COMPUTATION_RESULTS,
  OUTSIDE_POLLUTEDREGION,
  SET_GLOBAL_CONTENT,
} from "./types";
import { Computation, Content } from "../../types";

export function setContent(content: Content | null): ContentTypes {
  return {
    type: SET_UPLOAD_DATA,
    payload: content,
  };
}

export function setOutsidePollutedRegion(bool: boolean): ContentTypes {
  return {
    type: OUTSIDE_POLLUTEDREGION,
    payload: bool,
  };
}

export function setComputationResults(computation: Computation): ContentTypes {
  return {
    type: SET_COMPUTATION_RESULTS,
    payload: computation,
  };
}

export function setglobalComputation(content: any): ContentTypes {
  return {
    type: SET_GLOBAL_CONTENT,
    payload: content,
  };
}
