import {
  SET_BASELAYER,
  LayersState,
  LayersActionTypes,
  SET_ACTIVE_OVERLAYERS,
  REMOVE_ACTIVE_OVERLAYERS,
  ADD_BASELAYERS,
} from "./types";
import { Baselayer } from "../../types";

const initialState: LayersState = {
  baselayers: [],
  overlayers: [],
  activeOverlayers: [],
};

const layersReducer = (state = initialState, action: LayersActionTypes): LayersState => {
  switch (action.type) {
    case ADD_BASELAYERS:
      return {
        ...state,
        baselayers: action.payload,
      };
    case SET_BASELAYER:
      const baselayers: Baselayer[] = state.baselayers.map((baselayer) => {
        return {
          ...baselayer,
          isActive: baselayer.key === action.payload.key,
        };
      });
      return {
        ...state,
        baselayers,
      };
    case SET_ACTIVE_OVERLAYERS:
      return {
        ...state,
        activeOverlayers: [action.payload],
      };
    case REMOVE_ACTIVE_OVERLAYERS:
      return {
        ...state,
        activeOverlayers: [],
      };
    default:
      return state;
  }
};

export { layersReducer };
