import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import promiseMiddleware from "./promise.middleware";
import { persistReducer, createMigrate } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "../redux/root-reducer"; // the value from combineReducers
import migrations from "./migrations";

const middleware = [thunkMiddleware, promiseMiddleware()];

if (process.env.NODE_ENV !== "production") {
  const logger = createLogger({
    collapsed: true,
    logger: console,
    colors: {
      title: (action) => {
        if (action.type.endsWith("FULFILLED")) return "#006400";
        if (action.type.endsWith("REJECTED")) return "#B31B1B";
        return "#222222";
      },
      prevState: () => "#9E9E9E",
      action: () => "#03A9F4",
      nextState: () => "#4CAF50",
      error: () => "#F20404",
    },
  });
  middleware.push(logger);
}

const persistConfig = {
  key: "ovam",
  storage,
  version: 0,
  whitelist: ["map", "auth", "content"],
  migrate: createMigrate(migrations, { debug: true }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(...middleware));

export { store };
