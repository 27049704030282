import { MapPosition, Location } from "../../types";

export const SET_MAP_POSITION = "SET_MAP_POSITION";
export const SET_LAYER_OPACITY = "SET_LAYER_OPACITY";
export const SET_HISTORICAL_MARKERS = "SET_HISTORICAL_MARKERS";

export interface MapState {
  position: MapPosition;
  layerOpacity: number;
  markers: Location[];
}

interface SetMapPositionAction {
  type: typeof SET_MAP_POSITION;
  payload: MapPosition;
}

interface SetLayerOpacity {
  type: typeof SET_LAYER_OPACITY;
  payload: number;
}

interface SetHistoricalMarkers {
  type: typeof SET_HISTORICAL_MARKERS;
  payload: Location[];
}

export type MapActionTypes = SetMapPositionAction | SetLayerOpacity | SetHistoricalMarkers;
