import { combineReducers } from "redux";
import { authReducer } from "./auth/reducers";
import { contentReducer } from "./content/reducers";
import { layersReducer } from "./layers/reducers";
import { mapReducer } from "./map/reducers";
import { preferencesReducer } from "./preferences/reducers";

const rootReducer = combineReducers({
  auth: authReducer,
  content: contentReducer,
  layers: layersReducer,
  map: mapReducer,
  preferences: preferencesReducer,
});

export default rootReducer;
