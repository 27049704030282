import React from "react";
import styled from "styled-components";
import { getLegendGraphic } from "../services/ogc.service";
import { WMSTileLayerPropsWithName } from "../types";

interface Props {
  layer: WMSTileLayerPropsWithName;
}

export const LegendImage = ({ layer }: Props) => {
  return render();

  function render() {
    return (
      <Styles>
        <h3 className="title">{layer.name}</h3>
        <img alt="legend" src={getLegendGraphic(layer)} />
      </Styles>
    );
  }
};

const Styles = styled.div`
  padding: 0px 20px 20px 20px;

  .title {
    color: #222222;
    text-transform: uppercase;
    margin-top: 0;
  }
`;
