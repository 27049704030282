import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteProps } from "react-router-dom";
import { filter } from "lodash-es";
import { Point, CRS } from "leaflet";
import { Map, MapControlGroup, MapControlButton, MapControl, Tooltip, Window } from "marvin-ui-kit";
import { WMSTileLayerProps } from "react-leaflet";
import { setMapPosition, setHistoricalMarkers } from "../../redux/map/actions";
import { setIsMaximized } from "../../redux/preferences/actions";
import { setActiveOverlayers, removeActiveOverlayers } from "../../redux/layers/actions";
import {
  Baselayer,
  RootState,
  MapPosition,
  Content,
  Location,
  Measurements,
  RawLayer,
  GlobalComputation,
  WMSTileLayerPropsWithName,
} from "../../types";
import { getMarkerLocations, getLocationMeasurements } from "../../services/location.service";
import {
  getWmsLayer,
  getBaselayers,
  getLayerOpacity,
  getActiveOverlayers,
  getToken,
  getContent,
  getMarkers,
  getOverlayers,
} from "../../selectors";
import Icon from "../../components/icon";
import SearchBar from "../../components/search-bar/search-bar";
import { LayersMapControl } from "../../components/layers-map-control/layers-map-control";
import HistoricalMarkers from "../../components/markers/historical-markers";
import UserMarkers from "../../components/markers/user-markers";
import Legend from "../../components/legend/legend";
import { ParcelInformation } from "../../components/parcel-information/parcel-information";
import Popup from "../../components/popup/popup";
import { setContent } from "../../redux/content/actions";
import { LegendImage } from "../../components/legend-image";
import translations from "../../translations/nl.json";

interface OwnProps {
  location: any;
}

interface IStoreProps {
  activeOverlayers: WMSTileLayerProps[];
  baselayers: Array<Baselayer>;
  content: Content | null;
  globalComputation: GlobalComputation | null;
  isMaximized?: boolean;
  layerOpacity: number;
  mapPosition: MapPosition;
  markers: Location[];
  overlayers: RawLayer[];
  token: string | null;
  wmsLayer: Array<WMSTileLayerProps>;
}

interface IDispatchProps {
  removeActiveOverlayers: typeof removeActiveOverlayers;
  setActiveOverlayers: typeof setActiveOverlayers;
  setHistoricalMarkers: typeof setHistoricalMarkers;
  setIsMaximized: typeof setIsMaximized;
  setMapPosition: typeof setMapPosition;
  setContent: typeof setContent;
}

type Props = OwnProps & RouteProps & IStoreProps & IDispatchProps;

interface State {
  activeMarker: Point | null;
  isLayerPropertiesVisible: boolean;
  isMaximizeButtonActive: boolean;
  lat: number;
  lng: number;
  markerMeasurements: Measurements[];
  markerLocation: number[];
  markerType: "historical" | "user" | string;
  zoom: number;
  isLegendVisible: boolean;
}

class MapContainer extends Component<Props, State> {
  legendBtnRef: React.RefObject<HTMLDivElement>;
  infoBtnRef: React.RefObject<HTMLDivElement>;
  periodBtnRef: React.RefObject<HTMLDivElement>;
  state = {
    activeMarker: null,
    isLayerPropertiesVisible: true,
    isMaximizeButtonActive: false,
    lat: this.props.mapPosition.lat,
    lng: this.props.mapPosition.lng,
    markerMeasurements: [],
    markerLocation: [],
    markers: [],
    markerType: "historical",
    zoom: this.props.mapPosition.zoom,
    isLegendVisible: false,
  };

  constructor(props: Props) {
    super(props);
    this.legendBtnRef = React.createRef();
    this.infoBtnRef = React.createRef();
    this.periodBtnRef = React.createRef();
  }

  async componentDidMount() {
    const markers = await getMarkerLocations(this.props.token);
    this.props.setHistoricalMarkers(markers);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.content && prevProps.content !== this.props.content) {
      const lat = this.props.content.locations[0].location_4326.coordinates[1];
      const lng = this.props.content.locations[0].location_4326.coordinates[0];
      this.handleXmlUpload(lat, lng);
    }
  }

  componentWillUnmount() {
    // this removes the UserMarkers
    this.props.setContent(null);
  }

  updateMapPosition(position: any) {
    const { zoom, lat, lng } = position;
    this.setState({ zoom });
    this.props.setMapPosition({ lat, lng, zoom });
  }

  handleMapZoom(direction: "in" | "out") {
    const { lat, lng } = this.props.mapPosition;
    const { setMapPosition } = this.props;
    const { zoom } = this.state;

    if (direction === "in") {
      this.setState({ zoom: zoom + 1 });
      setMapPosition({ lat, lng, zoom: zoom + 1 });
    }
    if (direction === "out") {
      if (zoom === 0) return;
      this.setState({ zoom: zoom - 1 });
      setMapPosition({ lat, lng, zoom: zoom - 1 });
    }
  }

  handleMaximizeToggle = () => {
    this.props.setIsMaximized(!this.props.isMaximized);
    this.setState({
      isMaximizeButtonActive: !this.state.isMaximizeButtonActive,
    });
  };

  handleToggleLegend() {
    this.setState({
      isLegendVisible: !this.state.isLegendVisible,
    });
  }

  renderLegend(overlayer: WMSTileLayerPropsWithName[]) {
    if (!overlayer || !overlayer.length) {
      return null;
    }
    if (this.state.isLegendVisible) {
      return (
        <MapControl>
          <Window title={translations.LEGEND} onClose={this.handleToggleLegend.bind(this)}>
            <LegendImage layer={overlayer[0]} />
          </Window>
        </MapControl>
      );
    } else {
      // show legend button
      return (
        <MapControlButton onClick={this.handleToggleLegend.bind(this)}>
          <Tooltip text={"Show legend"} position="bottom" />
          <Icon name="list-alt" />
        </MapControlButton>
      );
    }
  }

  async handleSearchLocationClick(event: any) {
    const { lat, lng } = event;
    this.props.setMapPosition({ lat, lng, zoom: 17 });
    this.setState({
      lat,
      lng,
      zoom: 17,
    });
  }

  handleXmlUpload(lat: number, lng: number) {
    // Asked to zoom out when the location isn't inside the env
    if (this.props.content && !this.props.content.insidePollutedRegion) {
      this.props.setMapPosition({
        lat: 51.221077490196635,
        lng: 5.33763885498047,
        zoom: 11,
      });
      this.setState({
        lat: 51.221077490196635,
        lng: 5.33763885498047,
        zoom: 11,
      });
    } else {
      this.props.setMapPosition({ lat, lng, zoom: 17 });
      this.setState({
        lat,
        lng,
        zoom: 17,
      });
    }
  }

  async handleHistoricalMarkerClick(id: number, lat: number, lng: number) {
    try {
      const response = await getLocationMeasurements(id, this.props.token);
      this.setState({
        markerMeasurements: response.measurements,
        markerType: "historical",
        markerLocation: [lat, lng],
      });
    } catch (ex) {
      throw ex;
    }
  }

  async handleUserMarkerClick(id: number, lat: number, lng: number): Promise<void> {
    const { content } = this.props;

    this.setState({ markerType: "user", markerLocation: [lat, lng] });

    if (content) {
      const filteredLocation = content.locations
        .filter((location) => location.id === id)
        .map((filterdLocation) => filterdLocation.measurements)[0];

      this.setState({
        markerMeasurements: filteredLocation && filteredLocation,
      });
    }
  }

  render() {
    const { isMaximizeButtonActive, lat, lng, markerMeasurements, markerLocation, markerType, zoom } = this.state;
    const {
      activeOverlayers,
      baselayers,
      content,
      globalComputation,
      layerOpacity,
      markers,
      overlayers,
      setActiveOverlayers,
      removeActiveOverlayers,
      wmsLayer,
    } = this.props;
    const maxZoom = 18;

    const baseOverlayer = wmsLayer.filter((overlayer) => overlayer.layers === "zmkempen:cirkels");
    const allActiveOverlayers = [...activeOverlayers, ...baseOverlayer];
    const base = filter(baselayers, "isActive");

    return (
      <>
        <Map
          baselayers={base}
          crs={CRS.EPSG3857}
          lat={lat}
          lng={lng}
          onChange={this.updateMapPosition.bind(this)}
          overlayers={allActiveOverlayers.map((layer) => {
            return {
              ...layer,
              opacity: layerOpacity,
            };
          })}
          zoom={zoom}
        >
          {markers.length > 0 && (
            <HistoricalMarkers
              markers={markers}
              updateMapPosition={(position: MapPosition) => this.updateMapPosition(position)}
              handleHistoricalMarkerClick={(id: number, lat: number, lng: number) =>
                this.handleHistoricalMarkerClick(id, lat, lng)
              }
            />
          )}
          {globalComputation && content && (
            <UserMarkers
              content={content}
              handleUserMarkerClick={(id: number, lat: number, lng: number) => this.handleUserMarkerClick(id, lat, lng)}
            />
          )}
          <MapControlGroup position="BOTTOM_LEFT">{this.renderLegend(this.props.activeOverlayers)}</MapControlGroup>
          <MapControlGroup position="BOTTOM_RIGHT">
            <MapControlButton onClick={this.handleMapZoom.bind(this, "in")} disabled={!(zoom < maxZoom)}>
              <Icon name="plus" />
            </MapControlButton>
            <MapControlButton onClick={this.handleMapZoom.bind(this, "out")} disabled={!(zoom > 0)}>
              <Icon name="minus" />
            </MapControlButton>
            <MapControlButton isactive={isMaximizeButtonActive} onClick={() => this.handleMaximizeToggle()}>
              <Icon name="expand-arrows-alt" />
            </MapControlButton>
          </MapControlGroup>
          {markerLocation.length >= 1 && (
            <Popup markerLocation={markerLocation} markerMeasurements={markerMeasurements} markerType={markerType} />
          )}
        </Map>
        <MapControlGroup position="TOP_RIGHT">
          <SearchBar
            className="parcel-search"
            onSearchResultClick={(event: any) => this.handleSearchLocationClick(event)}
          />
          <span>
            <LayersMapControl
              allOverlayers={allActiveOverlayers}
              overlayers={overlayers}
              setActiveOverlayer={setActiveOverlayers}
              removeActiveOverlayers={removeActiveOverlayers}
            />
          </span>
          <span>
            <Legend />
          </span>
          <ParcelInformation content={content} />
        </MapControlGroup>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IStoreProps => {
  return {
    activeOverlayers: getActiveOverlayers(state),
    baselayers: getBaselayers(state),
    content: getContent(state),
    globalComputation: state.content.globalComputation,
    isMaximized: state.preferences.isMaximized,
    layerOpacity: getLayerOpacity(state),
    mapPosition: state.map.position,
    markers: getMarkers(state),
    overlayers: getOverlayers(),
    token: getToken(state),
    wmsLayer: getWmsLayer(),
  };
};

const mapDispatchToProps = {
  removeActiveOverlayers,
  setActiveOverlayers,
  setHistoricalMarkers,
  setIsMaximized,
  setMapPosition,
  setContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
