import { ServerError, TranslatedServerError } from "./../types/index";
import axios from "axios";
import { getCommonHeaders, processHeaders } from "./service.utils";

export const serverError = {
  response: {
    data: [
      {
        key: "server error",
        message: "Something went wrong, please contact a system administrator.",
      },
    ],
  },
};

export const xmlError = {
  response: {
    data: [
      {
        key: "xml error",
        message: "The file you uploaded isn't a correct formated xml.",
      },
    ],
  },
};

export async function getErrors(token: string | null, errorList: ServerError[]): Promise<TranslatedServerError[]> {
  const config = getCommonHeaders(token);
  const API_PATH = window._env_.REACT_APP_API_PATH;
  const response = await axios.post(`${API_PATH}/errors/list`, errorList, config);
  processHeaders(response.headers);
  return response.data;
}
