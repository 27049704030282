import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getDownloadPDF } from "../../services/computation.service";
import Icon from "../../components/icon";
import { connect } from "react-redux";
import { RootState, Content } from "../../types";
import { getContent, getToken } from "../../selectors";
import translations from "../../translations/nl.json";
import styles from "./styles.module.scss";
import { setOutsidePollutedRegion } from "../../redux/content/actions";

interface Props {
  content: Content | null;
  token: string | null;
  setOutsidePollutedRegion: typeof setOutsidePollutedRegion;
}

const OutAreaContainer = ({ content, token, setOutsidePollutedRegion }: Props) => {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      await getDownloadPDF(content && content.computationId, token);
      setIsLoading(false);
    } catch (ex) {
      setError(true);
    }
  };

  return (
    <div className={styles.noResultPopup}>
      <div onClick={() => setOutsidePollutedRegion(false)}>
        <Link to={"/analyse"} className={styles.buttonClose}>
          <Icon name="times" />
        </Link>
      </div>
      <p className={styles.noResultText}>
        De risicogrond bevindt zich <b>niet</b> in de wijde omgeving. Volg de verdere onderzoeksprocedures zoals
        aangegeven in het decreet.
      </p>
      <button onClick={() => handleClick()} className={styles.pdfRapport}>
        <Icon
          name={isLoading ? "processing" : "pdf-file"}
          className={isLoading ? styles.processingIcon : styles.pdfIcon}
        />
        <p className={styles.pdfText}>{translations.PDF_REPORT}</p>
      </button>
      {error && <p className={styles.downloadError}>{translations.PDF_ERROR}</p>}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    content: getContent(state),
    token: getToken(state),
  };
};

const mapDispatchToProps = {
  setOutsidePollutedRegion,
};

export default connect(mapStateToProps, mapDispatchToProps)(OutAreaContainer);
