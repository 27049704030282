import { Baselayer } from "../types";

export const baselayers: Baselayer[] = [
  {
    // GRB
    key: "grb",
    title: "Perceelkaart",
    url:
      "https://geo.api.vlaanderen.be/GRB/wmts?SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&LAYER=grb_bsk&STYLE=&FORMAT=image/png&TILEMATRIXSET=GoogleMapsVL&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
    attribution: "",
    image: "https://mapbase.marvin.vito.be/configurations/spaarbekkens/images/map-preview/GRB.png",
    isActive: true,
  },
  {
    // cartoDB
    key: "low-contrast",
    title: "Stratenkaart",
    url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}{r}.png",
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://cartodb.com/attributions">CartoDB</a>',
    image: "images/map-preview/low-contrast@2x.png",
    isActive: false,
  },
];
