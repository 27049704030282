import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { History } from "history";
import { setToken } from "../../redux/auth/actions";
import Icon from "../../components/icon";
import { setContent } from "../../redux/content/actions";
import translations from "../../translations/nl.json";
import styles from "./styles.module.scss";
import { auth } from "marvin-auth-kit";

interface IProps {
  history: History;
}

interface DispatchProps {
  setToken: typeof setToken;
  setContent: typeof setContent;
}

type Props = IProps & DispatchProps;

const LogoutContainer = ({ history, setContent, setToken }: Props) => {
  const handleLogout = () => {
    auth.logout();
    setToken(null);
    setContent(null);
    history.push("/login");
  };

  return (
    <div className={styles.logoutWrapper}>
      <p className={styles.logout} onClick={() => handleLogout()}>
        {translations.LOG_OUT}
      </p>
      <Link to={"/"} className={styles.buttonClose}>
        <Icon name="times" />
      </Link>
    </div>
  );
};

const mapDispatchToProps = {
  setToken,
  setContent,
};

export default connect(null, mapDispatchToProps)(LogoutContainer);
