import React from "react";
import { Marker as LeafletMarker } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { divIcon } from "leaflet";
import ReactDOMServer from "react-dom/server";
import Icon from "../icon";
import { Content, Location } from "../../types";
import styles from "./styles.module.scss";
import { getUserMarkerColor } from "../../utils/color.utils";

interface Props {
  content: Content;
  handleUserMarkerClick: (id: number, lat: number, lng: number) => Promise<void>;
}

const UserMarkers = (props: Props) => {
  const { content, handleUserMarkerClick } = props;

  const createClusterCustomIcon = function (cluster: any) {
    return divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: "marker-cluster-custom",
    });
  };

  const handleClick = (location: Location) => {
    handleUserMarkerClick(location.id, location.location_4326.coordinates[1], location.location_4326.coordinates[0]);
  };

  return (
    <MarkerClusterGroup
      disableClusteringAtZoom={16}
      iconCreateFunction={createClusterCustomIcon}
      spiderfyOnMaxZoom={false}
    >
      {content.locations.map((location: Location, index: number) => {
        return (
          <LeafletMarker
            key={`marker-${index}`}
            position={{
              lat: location.location_4326.coordinates[1],
              lng: location.location_4326.coordinates[0],
            }}
            onClick={() => handleClick(location)}
            classname={styles.xmlMarker}
            icon={divIcon({
              html: ReactDOMServer.renderToString(
                <div className={styles.iconWrapper}>
                  <Icon name="marker" size={30} fill={getUserMarkerColor(location)} />
                </div>
              ),
            })}
          />
        );
      })}
    </MarkerClusterGroup>
  );
};

export default UserMarkers;
