import React, { useState } from "react";
import { MapControlButton, MapControl, Window, Tooltip } from "marvin-ui-kit";
import historicalMarkerSoil from "../../assets/images/historical-marker-soil.svg";
import historicalMarkerGroundWater from "../../assets/images/historical-marker-ground-water.svg";
import historicalMarkerBoth from "../../assets/images/historical-marker-both.svg";
import Icon from "../icon";
import translations from "../../translations/nl.json";
import styles from "./styles.module.scss";
import { getUserMarkerColor } from "../../utils/color.utils";

const Legend = () => {
  const [isLegendVisible, setIsLegendVisible] = useState(false);
  const toggle = () => setIsLegendVisible(!isLegendVisible);

  if (isLegendVisible) {
    return (
      <MapControl>
        <Window title={translations.LEGEND} onClose={toggle}>
          <div className={styles.legendItems}>
            <div className={styles.legendItem}>
              <div className={styles.legendIcon}>
                <Icon name="marker" className={styles.icon} fill={getUserMarkerColor({ locationType: "SOIL" })} />
              </div>
              <div className={styles.legendInfo}>{translations.MARKER_LEGEND_INFO_SOIL}</div>
            </div>

            <div className={styles.legendItem}>
              <div className={styles.legendIcon}>
                <Icon
                  name="marker"
                  className={styles.icon}
                  fill={getUserMarkerColor({ locationType: "GROUND_WATER" })}
                />
              </div>
              <div className={styles.legendInfo}>{translations.MARKER_LEGEND_INFO_GROUND_WATER}</div>
            </div>

            <div className={styles.legendItem}>
              <div className={styles.legendIcon}>
                <Icon name="marker" className={styles.icon} fill={getUserMarkerColor({ locationType: "BOTH" })} />
              </div>
              <div className={styles.legendInfo}>{translations.MARKER_LEGEND_INFO_BOTH}</div>
            </div>

            <div className={styles.legendItem}>
              <div className={styles.legendIcon}>
                <img src={historicalMarkerSoil} alt="" className={styles.icon} />
              </div>
              <div className={styles.legendInfo}>{translations.HISTORICAL_MARKER_LEGEND_INFO_SOIL}</div>
            </div>
            <div className={styles.legendItem}>
              <div className={styles.legendIcon}>
                <img src={historicalMarkerGroundWater} alt="" className={styles.icon} />
              </div>
              <div className={styles.legendInfo}>{translations.HISTORICAL_MARKER_LEGEND_INFO_GROUND_WATER}</div>
            </div>
            <div className={styles.legendItem}>
              <div className={styles.legendIcon}>
                <img src={historicalMarkerBoth} alt="" className={styles.icon} />
              </div>
              <div className={styles.legendInfo}>{translations.HISTORICAL_MARKER_LEGEND_INFO_BOTH}</div>
            </div>
          </div>
        </Window>
      </MapControl>
    );
  } else {
    return (
      <Tooltip text={translations.LEGEND} position="left">
        <MapControlButton onClick={toggle} size="large">
          <Icon name="list-alt" />
        </MapControlButton>
      </Tooltip>
    );
  }
};

export default Legend;
