import { LOGIN_USER, SET_TOKEN, AuthtypeActions } from "./types";

export function loginUser(auth: boolean): AuthtypeActions {
  return {
    type: LOGIN_USER,
    payload: auth,
  };
}

export function setToken(token: string | null): AuthtypeActions {
  return {
    type: SET_TOKEN,
    payload: token,
  };
}
