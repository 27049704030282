import React from "react";
import classnames from "classnames";
import { RadioButton } from "marvin-ui-kit";
import styles from "./map-list-item.module.scss";

interface Props {
  onClick: () => void;
  previewUrl: string;
  title: string;
  isActive: boolean;
  description?: string;
}

const MapListItem = (props: Props) => {
  const cx = classnames(styles.container);

  return (
    <div className={cx} onClick={props.onClick}>
      <div className={styles.preview}>
        <img src={props.previewUrl} alt={`background preview of ${props.title}`} />
      </div>
      <div className={styles.information}>
        <div className={styles.title}>{props.title}</div>
      </div>
      <RadioButton
        isActive={props.isActive}
        borderColor={"#53682C"}
        activeBorderColor={"#53682C"}
        className={styles.toggle}
      />
    </div>
  );
};
export default MapListItem;
