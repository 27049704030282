import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Icon from "../../components/icon";
import MediumAndParameters from "../../components/medium-and-parameters/mediums-and-parameters";
import { History } from "history";
import { RootState, Content, GlobalComputation } from "../../types";
import { getContent, getToken } from "../../selectors";
import { setShowData } from "../../redux/preferences/actions";
import { setComputationResults } from "../../redux/content/actions";
import { getDownloadPDF } from "../../services/computation.service";
import translations from "../../translations/nl.json";
import styles from "./styles.module.scss";
import { WarningList } from "../../components/warning-list/warning-list";

interface IProps {
  history: History;
  showData: boolean;
}

interface StoreProps {
  content: Content | null;
  token: string | null;
  globalComputation: GlobalComputation | null;
}

interface DispatchProps {
  setShowData: typeof setShowData;
  setComputationResults: typeof setComputationResults;
}

type Props = IProps & StoreProps & DispatchProps;

const InAreaContainer = (props: Props) => {
  const { content, globalComputation, token, showData, setShowData, setComputationResults } = props;
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setShowData(false);
  }, [setShowData]);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      await getDownloadPDF(content && content.computationId, token);
      setIsLoading(false);
    } catch (ex) {}
  };

  const cxShowDataContainer = classnames(styles.container, {
    [styles.dataShown]: showData,
  });

  const cxShowDataHeader = classnames(styles.header, {
    [styles.dataShown]: showData,
  });

  const cxShowDataBody = classnames(styles.body, {
    [styles.dataShown]: showData,
  });

  return (
    <div className={cxShowDataContainer}>
      <div className={cxShowDataHeader}>
        <h1 className={styles.headerTitle}>{translations.ANALYSISRESULT}</h1>
        <button onClick={() => handleClick()} className={styles.pdfRapport}>
          <Icon
            name={isLoading ? "processing" : "pdf-file"}
            className={isLoading ? styles.processingIcon : styles.pdfIcon}
          />
          <p className={styles.pdfText}>{translations.PDF_REPORT}</p>
        </button>
      </div>
      <div className={cxShowDataBody}>
        <Link to={"/"} className={styles.buttonClose}>
          <Icon name="times" />
        </Link>
        <p className={styles.resultText}>{translations.ENV}</p>
        {globalComputation ? (
          <MediumAndParameters
            content={content}
            token={token}
            setComputationResults={setComputationResults}
            globalComputation={globalComputation}
            setShowData={setShowData}
          />
        ) : (
          <Icon className={styles.waitingIcon} name="processing" />
        )}

        <WarningList data={globalComputation?.warnings || []} />

        <h3>{translations.LEGEND}</h3>
        <p className={styles.legendInformation}>{translations.RED_LEGEND}</p>
        <p className={styles.legendInformation}>{translations.ORANGE_LEGEND}</p>
        <p className={styles.legendInformation}>{translations.GREEN_LEGEND}</p>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    showData: state.preferences.showData,
    content: getContent(state),
    token: getToken(state),
    globalComputation: state.content.globalComputation,
  };
};

const mapDispatchToProps = {
  setShowData,
  setComputationResults,
};

export default connect(mapStateToProps, mapDispatchToProps)(InAreaContainer);
