import { Baselayer } from "../../types";
import { WMSTileLayerProps } from "react-leaflet";

export const ADD_BASELAYERS = "ADD_BASELAYERS";
export const SET_LAYER_OPACITY = "SET_LAYER_OPACITY";
export const SET_BASELAYER = "SET_BASELAYER";
export const SET_ACTIVE_OVERLAYERS = "SET_ACTIVE_OVERLAYERS";
export const REMOVE_ACTIVE_OVERLAYERS = "REMOVE_ACTIVE_OVERLAYERS";

/**
 *  State
 */
export interface LayersState {
  baselayers: Array<Baselayer>;
  overlayers: Array<WMSTileLayerProps>;
  activeOverlayers: WMSTileLayerProps[];
}

/**
 * Actions
 */

interface AddBaselayersAction {
  type: typeof ADD_BASELAYERS;
  payload: Baselayer[];
}

interface SetBaselayerAction {
  type: typeof SET_BASELAYER;
  payload: Baselayer;
}

interface setActiveOverlayersAction {
  type: typeof SET_ACTIVE_OVERLAYERS;
  payload: WMSTileLayerProps;
}

interface RemoveActiveOverlayersAction {
  type: typeof REMOVE_ACTIVE_OVERLAYERS;
}

export type LayersActionTypes =
  | SetBaselayerAction
  | setActiveOverlayersAction
  | RemoveActiveOverlayersAction
  | AddBaselayersAction;
