import * as queryString from "query-string";

export function getLegendGraphic(layer: any): string {
  const queryParams = queryString.stringify({
    version: "1.1.0",
    request: "GetLegendGraphic",
    layer: layer.layers,
    format: "image/png",
  });
  return `${layer.url}?${queryParams}`;
}
