import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { History } from "history";
import { InputRange } from "marvin-ui-kit";
import MapListItem from "../../components/map-list-item/map-list-item";
import { setBaselayer } from "../../redux/layers/actions";
import { setLayerOpacity } from "../../redux/map/actions";
import { getLayerOpacity, getBaselayers } from "../../selectors";
import { Baselayer, RootState } from "../../types";
import translations from "../../translations/nl.json";
import styles from "./styles.module.scss";

interface Props {
  baselayers: Array<Baselayer>;
  dispatch: Dispatch;
  history: History;
}

interface StateProps {
  layerOpacity: number;
}

interface DispatchProps {
  setLayerOpacity: typeof setLayerOpacity;
  setBaselayer: typeof setBaselayer;
}

type IProps = Props & DispatchProps & StateProps;

class ConfigurationContainer extends Component<IProps> {
  handleBaselayerChange(baselayer: Baselayer) {
    this.props.setBaselayer(baselayer);
  }

  handleMapOpacityChange = (opacity: number) => {
    this.props.setLayerOpacity(opacity / 100);
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.headerTitle}>{translations.SETTINGS}</h1>
        </div>
        <div className={styles.body}>
          <div className={styles.bodyTitle}>{translations.OPACITY}</div>
          <InputRange
            min={0}
            max={100}
            step={5}
            value={this.props.layerOpacity * 100}
            onChange={this.handleMapOpacityChange.bind(this)}
          />
          <div className={styles.bodyTitle}>{translations.BASELAYER}</div>
          {this.props.baselayers.map((baselayer) => (
            <MapListItem
              key={baselayer.key}
              previewUrl={baselayer.image}
              title={baselayer.title}
              description={baselayer.description}
              isActive={baselayer.isActive}
              onClick={this.handleBaselayerChange.bind(this, baselayer)}
            />
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    baselayers: getBaselayers(state),
    layerOpacity: getLayerOpacity(state),
  };
};

const mapDispatchToProps = { setLayerOpacity, setBaselayer };

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationContainer);
