import React from "react";
import Boxplot from "../boxplot/boxplot";
import { Computation } from "../../types";
import translations from "../../translations/nl.json";
import styles from "./styles.module.scss";

interface Props {
  computation: Computation;
}

const ChartWrapper = ({ computation }: Props) => {
  const { report } = computation;

  return (
    <div className={styles.accordeonBody}>
      <div className={styles.boxplotWrapper}>
        {report.localBoxplot && (
          <Boxplot boxplotValues={report.localBoxplot} unit={report.unit} value={translations.LOCAL} />
        )}
        {report.regionalBoxplot && (
          <Boxplot boxplotValues={report.regionalBoxplot} unit={report.unit} value={translations.REGIONAL} />
        )}
      </div>
    </div>
  );
};

export default ChartWrapper;
