import { MapPosition } from "../../types";
import { SET_MAP_POSITION, SET_LAYER_OPACITY, MapActionTypes, SET_HISTORICAL_MARKERS } from "./types";
import { Location } from "../../types";

export function setMapPosition(position: MapPosition): MapActionTypes {
  return {
    type: SET_MAP_POSITION,
    payload: position,
  };
}

export function setLayerOpacity(opacity: number): MapActionTypes {
  return {
    type: SET_LAYER_OPACITY,
    payload: opacity,
  };
}

export function setHistoricalMarkers(markers: Location[]): MapActionTypes {
  return {
    type: SET_HISTORICAL_MARKERS,
    payload: markers,
  };
}
