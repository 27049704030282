import React from "react";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { Tooltip } from "marvin-ui-kit";
import Icon from "../icon";
import translations from "../../translations/nl.json";
import { GlobalComputation, Content } from "../../types";
import styles from "./main-navigation.module.scss";

interface Props {
  outsidePollutedRegion: boolean;
  content: Content | null;
  setOutsidePollutedRegion: (inside: boolean) => void;
  globalComputation: GlobalComputation | null;
}

const renderResultNavIcon = ({
  content,
  globalComputation,
  outsidePollutedRegion,
  setOutsidePollutedRegion,
}: Props) => {
  if (content === null) return;
  else if (globalComputation) {
    return (
      <Tooltip text={translations.RESULTS} position="right">
        <NavLink to="/result" className={styles.navigationItem} activeClassName={styles.active}>
          <Icon name="result" className={styles.icon} size={19} />
        </NavLink>
      </Tooltip>
    );
  } else {
    return (
      <div
        className={classnames(styles.customNavigationItem, {
          [styles.customActiveItem]: outsidePollutedRegion,
        })}
        onClick={() => setOutsidePollutedRegion(!outsidePollutedRegion)}
      >
        <Tooltip text={translations.RESULTS} position="right">
          <Icon name="result" className={styles.icon} size={19} />
        </Tooltip>
      </div>
    );
  }
};

const MainNavigation = (props: Props) => {
  return (
    <div className={styles.container}>
      <div>
        <Tooltip text={translations.ANALYSE} position="right">
          <NavLink to="/analyse" className={styles.navigationItem} activeClassName={styles.active}>
            <Icon name="analyse" className={styles.icon} size={19} />
          </NavLink>
        </Tooltip>

        {renderResultNavIcon(props)}

        <Tooltip text={translations.SETTINGS} position="right">
          <NavLink to="/instellingen" className={styles.navigationItem} activeClassName={styles.active}>
            <Icon name="sliders-h" className={styles.icon} size={19} />
          </NavLink>
        </Tooltip>
      </div>

      <Tooltip text={translations.USER} position="right">
        <NavLink to="/logout" className={styles.navigationItem} activeClassName={styles.active}>
          <Icon name="user" className={styles.icon} size={19} />
        </NavLink>
      </Tooltip>
    </div>
  );
};

export default MainNavigation;
