import React from "react";
import { Computation } from "../../types";
import translations from "../../translations/nl.json";
import styles from "./styles.module.scss";

interface Props {
  computation: Computation;
}

const InformationTable = ({ computation }: Props) => {
  const localValues = computation.report && computation.report.localBoxplot;
  const regionalValues = computation.report && computation.report.regionalBoxplot;
  const unit = computation.report.unit;

  const setMaximum = () => {
    if (localValues) {
      return localValues.value;
    } else if (regionalValues) {
      return regionalValues.value;
    } else return "/";
  };

  return (
    <div className={`${styles.accordeonBody} ${styles.numbersWrapper}`}>
      <div className={`${styles.tableWrapper} ${styles.tableHeader}`}>
        <p className={styles.tableText}>{translations.STATIC_NUMBER}</p>
        <p className={styles.tableText}>{translations.LOCAL}</p>
        <p className={styles.tableText}>{translations.REGIONAL}</p>
      </div>
      <div className={styles.row}>
        <p className={styles.tableText}>{translations.STATIC_UPPERBORDER}</p>
        <p className={styles.tableText}>
          {localValues ? localValues.upperWhisker : "/"} {localValues ? unit : ""}
        </p>
        <p className={styles.tableText}>
          {regionalValues ? regionalValues.upperWhisker : "/"} {localValues ? unit : ""}
        </p>
      </div>
      <div className={styles.row}>
        <p className={styles.tableText}>{translations.UPPER_QUARTILE}</p>
        <p className={styles.tableText}>
          {localValues ? localValues.upperQuartile : "/"} {localValues ? unit : ""}
        </p>
        <p className={styles.tableText}>
          {regionalValues ? regionalValues.upperQuartile : "/"} {localValues ? unit : ""}
        </p>
      </div>
      <div className={styles.row}>
        <p className={styles.tableText}>{translations.BETWEEN_QUARTILES}</p>
        <p className={styles.tableText}>
          {localValues ? localValues.median : "/"} {localValues ? unit : ""}
        </p>
        <p className={styles.tableText}>
          {regionalValues ? regionalValues.median : "/"} {localValues ? unit : ""}
        </p>
      </div>
      <div className={styles.row}>
        <p className={styles.tableText}>{translations.LOWER_QUARTILE}</p>
        <p className={styles.tableText}>
          {localValues ? localValues.lowerQuartile : "/"} {localValues ? unit : ""}
        </p>
        <p className={styles.tableText}>
          {regionalValues ? regionalValues.lowerQuartile : "/"} {localValues ? unit : ""}
        </p>
      </div>
      <div className={styles.row}>
        <p className={styles.tableText}>{translations.STATIC_LOWERBORDER}</p>
        <p className={styles.tableText}>
          {localValues ? localValues.lowerWhisker : "/"} {localValues ? unit : ""}
        </p>
        <p className={styles.tableText}>
          {regionalValues ? regionalValues.lowerWhisker : "/"} {localValues ? unit : ""}
        </p>
      </div>
      <div className={styles.row}>
        <p className={styles.tableText}>{translations.NUMBER_OF_VALUES}</p>
        <p className={styles.tableText}>{computation.report.localNbMeasurements}</p>
        <p className={styles.tableText}>{computation.report.regionalNbMeasurements}</p>
      </div>
      <div className={styles.row}>
        <p className={styles.tableText}>{translations.MAXIMUM_CON}</p>
        <div className={styles.maxValItem}>
          <p className={styles.tableText}>
            {setMaximum()} {unit}
          </p>
        </div>
      </div>
    </div>
  );
};

export default InformationTable;
