import React, { useState } from "react";
import { MapControlButton, MapControl, Window, Tooltip } from "marvin-ui-kit";
import Icon from "../icon";
import { Content, Parcel } from "../../types";
import translations from "../../translations/nl.json";
import styles from "./styles.module.scss";

interface Props {
  content: Content | null;
}

export const ParcelInformation = (props: Props) => {
  const { content } = props;
  const [isPlotInfoOpen, setisPlotInfoOpen] = useState(false);
  const toggle = () => setisPlotInfoOpen(!isPlotInfoOpen);

  return render();

  function render() {
    if (isPlotInfoOpen) {
      return (
        <MapControl>
          <Window title={translations.PLOT_INFO} onClose={toggle} className={styles.wrapper}>
            {content && content.parcels ? (
              content.parcels.map((parcel: Parcel) => {
                return (
                  <div key={parcel.capakey}>
                    <div className={styles.parcelHeaderWrapper}>
                      <Icon name="arrow-down" className={styles.arrowHeader} />
                      <h2>{parcel.capakey}</h2>
                    </div>
                    {renderParcelInfo(parcel)}
                  </div>
                );
              })
            ) : (
              <p>{translations.NO_PLOT}</p>
            )}
          </Window>
        </MapControl>
      );
    } else {
      return (
        <Tooltip text={translations.PLOT} position="left">
          <MapControlButton onClick={toggle} size="large">
            <Icon name="parcel-info" />
          </MapControlButton>
        </Tooltip>
      );
    }
  }

  function renderParcelInfo(parcel: Parcel) {
    return (
      <div>
        <div className={styles.parcelInfoWrapper}>
          <p>{translations.PARCEL_ID}</p>
          <p>{parcel.registerId}</p>
        </div>
        <div className={styles.parcelInfoWrapper}>
          <p>{translations.PARCEL_DESCRIPTION}</p>
          <p>{parcel.registerDescription}</p>
        </div>
        <div className={styles.parcelInfoWrapper}>
          <p>{translations.PARCEL_SECTION}</p>
          <p>{parcel.section}</p>
        </div>
        <div className={styles.parcelInfoWrapper}>
          <p>{translations.PARCEL_TERRAINNUMBER}</p>
          <p>{parcel.terrainNumber}</p>
        </div>
        <div className={styles.parcelInfoWrapper}>
          <p>{translations.PARCEL_EXPO1}</p>
          <p>{parcel.exponent1}</p>
        </div>
        <div className={styles.parcelInfoWrapper}>
          <p>{translations.PARCEL_EXPO2}</p>
          <p>{parcel.exponent2}</p>
        </div>
      </div>
    );
  }
};
